body {
  background-color: #051328;
  font-family: sofia-pro;
  color: #878e98;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 500;
}

h1 {
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: sofia-pro;
  color: #e0e0e0;
  font-size: 3.5em;
  line-height: 1.5;
  font-weight: 700;
}

h2 {
  margin-top: 0px;
  margin-bottom: 24px;
  color: #dfdfdf;
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
}

p {
  margin-bottom: 10px;
  color: rgba(255, 255, 255, 0.72);
  line-height: 1.6;
}

a {
  color: #051328;
  text-decoration: none;
}

.container {
  width: 1200px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 32px;
  padding-left: 32px;
}

.container.is--hero {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1vw;
  text-align: center;
}

.container.is--footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.container.is--footer-bottom {
  text-align: center;
}

.container.is--message-send {
  font-size: 1vw;
}

.container.is--nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.container.is--nav-topic-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.container.is--component {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.container.bp-hero {
  position: relative;
}

.btn {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 1.5rem 3rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none #000;
  background-color: #e7e7e7;
  color: #051328;
  text-decoration: none;
  cursor: pointer;
}

.btn.submit {
  overflow: hidden;
  background-color: transparent;
  color: #fff;
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.btn.bth {
  padding: 12px 24px;
  border-style: solid;
  border-color: #0cf;
  border-radius: 20px;
  background-color: rgba(0, 204, 255, 0);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
  line-height: 1;
}

.btn.bth:hover {
  background-color: #0cf;
  box-shadow: 1px 1px 12px 0 rgba(0, 204, 255, 0.27);
  color: #051328;
}

.hero {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 100vh;
  max-height: 100vh;
  padding-top: 140px;
  padding-bottom: 10vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.animate-this-shit {
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 2.2em;
}

.subline {
  padding: 6px 18px;
  border-radius: 20px;
  background-color: #00ffe1;
  color: #051328;
  font-size: 13px;
}

.subline.ontop {
  margin-bottom: 12px;
}

.c-nav-item-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -12px;
  margin-bottom: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-item-link {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 12px;
  margin-left: 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
  cursor: pointer;
}

.nav-item-link:hover {
  color: #00ffe1;
}

.nav-link-icon {
  position: absolute;
  left: -22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 22px;
  padding-right: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.contact {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 998;
  display: none;
  overflow: auto;
  min-height: 100vh;
  padding-top: 140px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-top: 1px solid hsla(30, 0%, 100%, 0.29);
  background-color: #051328;
}

.footer-nav-holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: -22px;
  margin-bottom: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.in--footer {
  margin-right: 6px;
  margin-left: 6px;
  padding: 10px 12px 10px 4px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(30, 0%, 100%, 0.29);
  border-radius: 20px;
  background-color: #051328;
}

.db {
  display: block;
}

.textarea {
  max-width: 100%;
  min-height: 280px;
  margin-bottom: 0px;
  padding-top: 12px;
  padding-right: 24px;
  padding-left: 24px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(30, 0%, 100%, 0.29);
  border-radius: 5px;
  background-color: #121f33;
  color: #cacaca;
  font-size: 19px;
}

.form-block {
  width: 100%;
  max-width: 600px;
  min-width: 400px;
  margin-top: 60px;
}

.submit-wrapper {
  position: absolute;
  left: auto;
  top: auto;
  right: -14%;
  bottom: -25%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 160px;
  max-width: 160px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
}

.form {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr auto;
  grid-template-columns: 1fr auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.submit-button {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 10;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #00ffe1;
  opacity: 0;
}

.footer-bottom {
  margin-top: 80px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid hsla(30, 0%, 100%, 0.29);
}

.heart {
  display: inline-block;
  width: 20px;
  height: 20px;
  color: #00ffe1;
}

.footer-bottom-link {
  position: relative;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
}

.footer-bottom-link:hover {
  color: #00ffe1;
}

.wlfwc-holder {
  position: relative;
  z-index: 20;
  color: #fff;
}

.page-content {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 100vw;
}

.section.is--message-send {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  min-height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p {
  font-size: 18px;
}

.page-background {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.bg-blur {
  position: absolute;
  width: 25vw;
  height: 25vw;
  border-radius: 50%;
  -webkit-filter: blur(100px);
  filter: blur(100px);
}

.bg-blur.inner {
  position: static;
  width: 100%;
  height: 100%;
}

.bg-blur.inner._1 {
  background-color: #00f;
  opacity: 0.1;
}

.bg-blur._1 {
  left: 25%;
  top: -25vw;
  right: auto;
  bottom: auto;
  width: 50vw;
  height: 50vw;
}

.hero-p-wrapper {
  max-width: 760px;
  margin-top: 24px;
  margin-right: auto;
  margin-left: auto;
  font-size: 18px;
}

.blue-dot {
  font-size: 14px;
}

.nav-trigger {
  position: fixed;
  top: 32px;
  right: 32px;
  z-index: 999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80px;
  height: 80px;
  padding-top: 6px;
  padding-bottom: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  cursor: pointer;
}

.nav__list-item.is--dropdown-item {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.nav {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 999;
  display: none;
  padding-top: 20px;
  padding-bottom: 12px;
}

.nav-item-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -12px;
  margin-bottom: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-topic-p {
  margin-bottom: 0px;
}

.nav-item-link-2 {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 12px;
  margin-left: 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #051328;
}

.nav-item-link-2:hover {
  color: #00f;
}

.noiser {
  display: none;
}

.nav-topic-wrap {
  overflow: hidden;
  width: 100%;
  margin-top: 12px;
  border-top: 1px solid hsla(30, 0%, 100%, 0.29);
}

.logo-embed {
  display: none;
  width: 160px;
}

.nav-logo-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  height: 60px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
}

.nav-logo-link:hover {
  color: #00ffe1;
}

.main {
  padding-top: 60px;
  padding-bottom: 60px;
}

.clone-the-child-of-this {
  width: 100%;
}

.share-button-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 120px;
  padding-right: 40px;
  padding-left: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(30, 0%, 100%, 0.29);
  border-radius: 80px;
}

.share-btn__wrapper {
  display: -ms-grid;
  display: grid;
  margin-left: 6px;
  grid-auto-columns: 1fr;
  grid-column-gap: 6px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto auto auto auto auto;
  grid-template-columns: auto auto auto auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.share-btn {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 44px;
  height: 44px;
  margin-right: 6px;
  margin-left: 6px;
  padding-top: 10px;
  padding-right: 12px;
  padding-left: 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(30, 0%, 100%, 0.29);
  border-radius: 40px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 255, 0);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fff;
}

.share-btn:hover {
  height: 80px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 255, 0.4);
  color: #00f;
}

.share-btn-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.share-btn-name {
  position: absolute;
  top: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.clipboard-message-wrap {
  position: fixed;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 99;
  display: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.clipboard-message {
  margin-bottom: 32px;
  padding: 10px 20px 10px 24px;
  border-radius: 40px;
  background-color: rgba(0, 0, 255, 0.8);
  color: #fff;
  font-size: 14px;
  line-height: 1;
  text-align: center;
}

.share-buttons-heading {
  position: absolute;
  top: -16px;
  padding-right: 12px;
  padding-left: 12px;
  background-color: #051328;
  color: #fff;
}

.dropdown-list {
  position: static;
  display: block;
  overflow: hidden;
  height: auto;
  max-width: 960px;
  margin-top: 32px;
  padding-left: 32px;
  background-color: transparent;
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
}

.dropdown-list.w--open {
  height: auto;
}

.code {
  max-width: 100%;
  margin-top: 20px;
  margin-right: auto;
  margin-bottom: 20px;
}

.code p {
  margin-bottom: 0px;
  font-family: Inconsolata, monospace;
  font-size: 16px;
}

.code.hint {
  margin-top: -12px;
}

.code.faq {
  margin-top: 12px;
  margin-bottom: 0px;
}

.code.demo {
  margin-left: 0px;
}

.dropdown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
}

.dropdown-toggle {
  color: #fff;
}

.guide-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 24px;
  margin-bottom: 12px;
  margin-left: -32px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.guide-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  margin-right: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.guide-wf-div {
  display: inline-block;
  padding-right: 6px;
  padding-left: 6px;
  border-radius: 5px;
  background-color: #3b79c3;
  font-family: Inconsolata, monospace;
  color: #fff;
  font-size: 14px;
}

.tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 960px;
  margin-top: 32px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.tab-link {
  margin-right: 6px;
  margin-left: 6px;
  padding: 8px 24px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(30, 0%, 100%, 0.29);
  border-radius: 20px;
  background-color: rgba(0, 204, 255, 0);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fff;
  font-size: 16px;
  line-height: 1;
}

.tab-link:hover {
  border-color: #0cf;
  background-color: #0cf;
  color: #051328;
}

.tab-link.w--current {
  border-color: #0cf;
  background-color: #0cf;
  color: #051328;
}

.tab-link.no-link {
  display: none;
  border-style: none;
  background-color: transparent;
  cursor: default;
}

.tabs-content {
  width: 100%;
  max-width: 100%;
  margin-top: 32px;
  padding-left: 32px;
  color: #fff;
}

.wip {
  position: fixed;
  left: auto;
  top: auto;
  right: 32px;
  bottom: 32px;
  z-index: 4;
  padding: 8px 12px;
  border-radius: 20px;
  background-color: rgba(255, 191, 0, 0.32);
  color: #ffda07;
  font-size: 13px;
  line-height: 1;
  text-transform: uppercase;
}

.blog-item {
  width: 420px;
  max-width: 100%;
}

.blog-preview__box {
  position: relative;
  width: 100%;
}

.blog-preview__body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 4px;
  padding-right: 12px;
  padding-left: 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #05122b;
  color: #fff;
}

.blog-preview__title {
  margin-top: 12px;
  font-size: 22px;
  line-height: 1.5;
}

.blog-preview__thumb {
  width: 100%;
}

.blog-preview__rm-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 32px;
  height: 32px;
  padding-left: 2px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(30, 0%, 100%, 0.29);
  border-radius: 20px;
  background-color: #05122b;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-size: 14px;
  line-height: 1;
}

.blog-preview__rm-btn:hover {
  width: 114px;
  background-color: #fff;
  color: #051328;
}

.blog-preview__rm-btn-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 28px;
  height: 28px;
  min-height: 28px;
  min-width: 28px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block {
  width: 92px;
}

.collection-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-post-hero {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 100px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.bp__bg-wrap {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
}

.bp__bg-image {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  max-height: 100%;
  opacity: 0.1;
  -webkit-filter: blur(40px);
  filter: blur(40px);
  -o-object-fit: cover;
  object-fit: cover;
}

.bp-hero__content-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 12px;
  -ms-grid-columns: 0.5fr 1fr;
  grid-template-columns: 0.5fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
}

.bp-hero__image {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  border-radius: 12px;
}

.cover {
  width: 100%;
  height: 100%;
  max-height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.cover.absolute {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
}

.bp-hero__content-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.bp-hero__heading {
  font-size: 36px;
}

.note-wrap {
  padding: 12px 12px 2px;
  background-color: rgba(0, 204, 255, 0.32);
}

.note-p {
  color: #0cf;
  font-size: 16px;
}

.swiper-container {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  -webkit-transform: perspective(1200px);
  transform: perspective(1200px);
}

.swiper-wrapper {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
}

.swiper-slide {
  position: relative;
  display: block;
  overflow: hidden;
  width: 30%;
  height: 50vh;
  margin-top: 8vh;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 20px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  text-align: center;
}

.slide-content {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 2;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.slide-content.slide-1 {
  background-color: #972323;
}

.slide-content.slide-2 {
  background-color: #559723;
}

.slide-content.slide-3 {
  background-color: #22429b;
}

.slide-heading {
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  font-size: 48px;
  line-height: 1.4;
}

.slide-inner {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.slide-image {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  max-height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.slide-category {
  margin-bottom: 12px;
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
  color: #dfdfdf;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 7px;
  text-transform: uppercase;
}

.wheel-slider {
  position: relative;
}

._wf-button__wrapper {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 900;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.preloader {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 9999;
  display: none;
  overflow: hidden;
  background-color: #222;
}

.preloader__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.swiper-slide-shadow-left {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(transparent), to(rgba(0, 0, 0, 0.5)));
  background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.5));
}

.swiper-slide-shadow-right {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5), transparent);
}

.contact-trigger-wrap {
  position: absolute;
  left: 0%;
  top: auto;
  right: auto;
  bottom: 0%;
  display: none;
  padding-bottom: 2vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.scroll-btn {
  position: relative;
  z-index: 50;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 6vh;
  height: 6vh;
  padding: 1.5rem 3rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none #000;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: hsla(0, 0%, 90.6%, 0.5);
  text-decoration: none;
  cursor: pointer;
}

.scroll-btn:hover {
  color: #00ffe1;
}

.scroll-btn.btn-twenty {
  width: 120px;
  height: 120px;
  background-color: transparent;
  color: #051328;
  text-transform: uppercase;
}

.arrow__btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 2vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-item-text-mobile {
  display: none;
}

.nav-item-text-desktop:hover {
  color: #fff;
}

.swiper-navigation {
  z-index: 40;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.swiper-navigation.right {
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.swiper-nav-btn {
  position: absolute;
  left: 20%;
  top: 60%;
  z-index: 40;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  height: 60px;
  padding: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-transform: rotate(-20deg);
  -ms-transform: rotate(-20deg);
  transform: rotate(-20deg);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fff;
}

.swiper-nav-btn:hover {
  color: #00ffe1;
}

.swiper-nav-btn.prev:hover {
  color: #fff;
}

.swiper-nav-btn.next {
  left: auto;
  right: 20%;
  -webkit-transform: rotate(20deg);
  -ms-transform: rotate(20deg);
  transform: rotate(20deg);
}

.swiper-nav-btn.next:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.swiper-nav-btn-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.slide-text-wrap {
  overflow: hidden;
}

.modal-close-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-bottom: 60px;
  margin-left: auto;
  padding: 12px 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 40px;
  background-color: hsla(0, 0%, 100%, 0.09);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fff;
  line-height: 1;
  letter-spacing: 4px;
  text-transform: uppercase;
  cursor: pointer;
}

.modal-close-wrap:hover {
  background-color: #00ffe1;
  color: #051328;
}

.modal-close-icon {
  width: 24px;
  height: 24px;
  margin-left: 12px;
  border-radius: 100%;
  background-color: #00ffe1;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  color: #051328;
}

.modal-trigger {
  margin-left: 32px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
}

.controls {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-bottom: 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.control-btn {
  position: relative;
  z-index: 80;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 48px;
  height: 48px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  cursor: pointer;
}

.control-btn:hover {
  background-color: rgba(249, 252, 251, 0.54);
  color: #051328;
}

.icon-show-slider-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 28px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.body {
  background-color: #222;
  font-family: Montserrat, sans-serif;
  color: #e7e7e7;
}

.heading {
  color: #d6d6d6;
}

.navbar {
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: block;
  padding-top: 20px;
  border-bottom: 1px solid #e7e7e7;
  background-color: #222;
  color: #e7e7e7;
}

.heading-2 {
  margin-bottom: 0px;
  font-family: Montserrat, sans-serif;
  color: #e7e7e7;
  font-size: 2em;
  line-height: 16px;
  text-align: left;
  text-transform: uppercase;
}

.brand {
  top: 20px;
  bottom: 20px;
}

.nav-link {
  color: #e7e7e7;
}

.nav-link:hover {
  font-weight: 700;
}

.nav-link.w--current {
  color: #e7e7e7;
  font-weight: 700;
}

.nav-link-2 {
  color: #e7e7e7;
}

.nav-link-2:hover {
  font-weight: 700;
}

.nav-link-3 {
  border-right: 1px none #e7e7e7;
  border-radius: 0.5px;
  color: #e7e7e7;
}

.nav-link-3:hover {
  font-weight: 700;
}

.nav-link-3.w--current {
  color: #e7e7e7;
  font-weight: 700;
}

.nav-link-3.buttom {
  margin-top: 17px;
  margin-left: 10px;
  padding: 3px 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #dfdfdf;
  border-radius: 20px;
}

.nav-link-3.buttom:hover {
  margin-top: 17px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #dfdfdf;
  color: #222;
  font-weight: 500;
}

.nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 5px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.text-block-2 {
  margin-top: 60px;
  font-family: Montserrat, sans-serif;
  color: #dfdfdf;
  font-size: 2.5em;
  font-weight: 700;
  text-align: center;
}

.container-2 {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  margin-top: 80px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  -o-object-fit: fill;
  object-fit: fill;
  z-index: 2;
}

.container-3 {
  display: block;
  margin-right: auto;
  margin-left: auto;
  font-family: Montserrat, sans-serif;
  color: #e7e7e7;
}

.menu-button {
  padding-bottom: 18px;
  font-size: 24px;
}

.body-2 {
  background-color: #222;
  font-family: Montserrat, sans-serif;
}

.container-4 {
  position: static;
  display: -ms-grid;
  display: grid;
  margin-top: 140px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.section-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 80vh;
  margin-top: 140px;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}

.section-2.individual-collection-page {
  margin-top: 90px;
  border-top-style: none;
}

.section-2.sold-warpper {
  margin-top: 97px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  border-top-style: none;
  opacity: 1;
}

.columns {
  display: none;
  overflow: hidden;
  width: auto;
  height: auto;
  margin-right: auto;
  margin-left: auto;
}

.image {
  width: 50vw;
  height: 50vw;
  padding: 100px;
}

.text-block-3 {
  padding-top: 100px;
  padding-right: 60px;
}

.navbar-2 {
  position: static;
}

.container-5 {
  overflow: hidden;
  max-height: 50vw;
  max-width: 50vw;
}

.container-6 {
  display: none;
  margin-top: 40px;
}

.text-block-4 {
  display: none;
}

.feature-image-wrapper {
  position: relative;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 78vh;
  max-width: 90vw;
  min-width: 170px;
  padding: 10px;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 1px;
  text-align: center;
}

.image-2 {
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 90vw;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  -o-object-fit: fill;
  object-fit: fill;
}

.print {
  display: block;
  margin-top: 20px;
  padding: 20px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 2fr 1fr;
  grid-template-columns: 2fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}

.print._1 {
  margin-top: 0px;
  border-top-style: none;
}

.print.body-2 {
  margin-top: 0px;
}

.print.title {
  margin-top: 0px;
  border-top-style: none;
}

.container-7 {
  display: block;
  padding-bottom: 0px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border: 1px none #000;
}

.text-block-5 {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}

.italic-text {
  color: #fff;
  font-style: italic;
  font-weight: 500;
}

.button {
  border: 1px solid #e7e7e7;
  border-radius: 19px;
  background-color: rgba(56, 152, 236, 0);
}

.button:hover {
  background-color: #e7e7e7;
  color: #222;
}

.columns-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.column-3 {
  text-align: center;
}

.section-3 {
  margin-top: 20px;
  padding: 20px;
  border-style: none none solid;
  border-width: 1px;
  border-color: #e7e7e7;
}

.text-block-6 {
  font-weight: 300;
  color: #878e98;
}

.gallery {
  margin: 20px 0px 0px;
  padding: 20px;
  border-style: none none solid;
  border-width: 1px;
  border-color: #e7e7e7;
}

.gallery.my-gallery {
  display: block;
  margin-top: 0px;
  margin-right: auto;
  margin-left: auto;
  padding: 0px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  border-style: solid none;
}

.text-block-7 {
  display: block;
  margin-top: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #e7e7e7;
  font-style: italic;
  font-weight: 500;
  text-align: center;
}

.image-3 {
  padding: 10px 0px;
}

.minted-nft {
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 20px;
  padding-left: 10px;
  border-style: none solid solid none;
  border-width: 1px;
  border-color: #000 #e7e7e7 #e7e7e7 #000;
  cursor: pointer;
}

.minted-nft.merch1 {
  border-bottom-style: none;
}

.minted-nft-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: none solid solid none;
  border-width: 1px;
  border-color: #000 #e7e7e7 #e7e7e7 #000;
  cursor: pointer;
}

.minted-nft-2.merch-2 {
  border-bottom-style: none;
}

.minted-nft-2.midfdle {
  border-style: none solid solid;
  border-color: #e7e7e7;
}

.minted-nft-3 {
  padding-bottom: 18px;
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #e7e7e7;
  cursor: pointer;
}

.minted-nft-3.merch-3 {
  border-bottom-style: none;
}

.column-7 {
  text-align: left;
}

.content {
  min-height: 100vh;
  padding-top: 64px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.switch__handle {
  width: 18px;
  height: 18px;
  border-radius: 10px;
  background-color: #fff;
}

.switch {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -4px;
  margin-left: -4px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex.flex--justify-space-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flex.flex--align--start {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.switch__toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  margin-right: 4px;
  margin-left: 4px;
  padding: 2px;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-transition: all 500ms cubic-bezier(.25, .46, .45, .94);
  transition: all 500ms cubic-bezier(.25, .46, .45, .94);
  cursor: pointer;
}

.switch__toggle:hover {
  background-color: #000;
}

.job {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.job:last-child {
  border-bottom-style: none;
}

.content__header {
  padding: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.heading-3 {
  font-size: 20px;
  font-weight: 700;
}

.switch__label {
  margin-right: 4px;
  margin-left: 4px;
  color: rgba(39, 39, 39, 0.65);
  font-size: 13px;
  font-weight: 600;
}

.main-2 {
  min-height: 100vh;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.container-8 {
  width: 100%;
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 32px;
  padding-left: 32px;
}

.heading-4 {
  margin-bottom: 20px;
  font-family: Montserrat, sans-serif;
  font-size: 2em;
  cursor: pointer;
}

.about {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 20px;
  border-bottom: 1px solid #e7e7e7;
}

.about.page {
  border-top: 1px solid #e7e7e7;
}

.about.page._1 {
  margin-top: auto;
  padding-bottom: 20px;
  border-top-style: none;
}

.about.page._1.top {
  margin-top: 0px;
}

.footer {
  overflow: hidden;
  border-top: 1px solid #e7e7e7;
  background-color: #222;
  color: #fff;
}

.wrap {
  display: block;
  width: 940px;
  margin-right: auto;
  margin-left: auto;
}

.wrap.w50-spcbetween {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.link-dark {
  opacity: 0.6;
  -webkit-transition: color 400ms ease, opacity 400ms ease, -webkit-transform 400ms ease;
  transition: color 400ms ease, opacity 400ms ease, -webkit-transform 400ms ease;
  transition: color 400ms ease, transform 400ms ease, opacity 400ms ease;
  transition: color 400ms ease, transform 400ms ease, opacity 400ms ease, -webkit-transform 400ms ease;
  font-family: Montserrat, sans-serif;
  color: #e7e7e7;
  font-size: 32px;
  line-height: 24px;
  font-weight: 700;
  cursor: pointer;
}

.link-dark:hover {
  opacity: 1;
  color: #fff;
}

.link-dark:active {
  opacity: 0.9;
}

.home-footer-socicon-wrap {
  margin-right: 20px;
}

.home-footer-socicon-wrap.home-footer-socicon-wrap-last {
  margin-right: 0px;
}

.home-footer-socicon {
  opacity: 0.6;
}

.home-footer-socicon:hover {
  opacity: 1;
}

.home-footer-socicon:active {
  opacity: 0.85;
}

.container-9 {
  display: block;
  overflow: hidden;
  padding-right: 10px;
  padding-left: 10px;
}

.container-10 {
  display: block;
}

.my-gallery-display {
  display: block;
}

.text-block-8 {
  margin-top: 10px;
  color: #e7e7e7;
  text-align: center;
}

.column-9 {
  margin-right: auto;
  margin-left: auto;
  border-right: 1px solid #e7e7e7;
  text-align: center;
}

.image-4 {
  max-width: 50%;
}

.columns-3 {
  text-align: center;
}

.column-10 {
  border-right: 1px solid #e7e7e7;
}

.text-span {
  text-decoration: underline;
}

.text-span-2 {
  text-decoration: underline;
}

.already-minted-nft-display-title {
  padding: 20px;
  border-bottom: 1px solid #e7e7e7;
}

.text-block-9 {
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

.text-block-9.all {
  display: block;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 20px;
  background-color: #222;
  color: #e7e7e7;
  font-size: 1em;
  text-align: center;
}

.text-block-9.for-sale {
  border: 1px solid #e7e7e7;
  border-radius: 20px;
  text-align: center;
}

.text-block-9.all.detail-filter-active {
  background-color: #e7e7e7;
  color: #222;
  font-size: 1em;
}

.text-block-9.sold {
  border: 1px solid #e7e7e7;
  border-radius: 20px;
  text-align: center;
}

.section-4 {
  display: block;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.text-block-10 {
  margin-right: auto;
  margin-left: auto;
}

.section-5 {
  margin-top: 140px;
  margin-right: auto;
  margin-left: auto;
}

.heading-5 {
  font-family: Montserrat, sans-serif;
  font-size: 2em;
  text-align: center;
}

.italic-text-3 {
  color: #c2c2c2;
}

.section-6 {
  margin-top: 140px;
  margin-right: auto;
  margin-left: auto;
  border-top: 1px none #fff;
}

.heading-6 {
  margin-bottom: 20px;
  font-family: Montserrat, sans-serif;
  font-size: 2em;
}

.text-span-3 {
  font-weight: 500;
}

.text-span-4 {
  padding-right: 10px;
  padding-left: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 15px;
  line-height: 2;
}

.text-block-11 {
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 20px;
  color: #fff;
  font-weight: 500;
  text-align: center;
}

.column-11 {
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
  border-top-style: none;
  border-top-color: #e7e7e7;
}

.text-span-5 {
  font-weight: 700;
  white-space: normal;
  word-break: break-all;
}

.columns-4 {
  margin: 0px;
}

.text-block-12 {
  margin-top: 20px;
  font-size: 34px;
  font-style: italic;
  text-align: center;
}

.container-11 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.text-block-13 {
  text-align: center;
}

.text-span-6 {
  font-style: italic;
  white-space: normal;
  word-break: break-all;
}

.text-span-7 {
  font-style: italic;
}

.text-span-8 {
  font-size: 20px;
  font-weight: 300;
}

.text-span-9 {
  font-size: 20px;
  font-weight: 300;
}

.text-span-10 {
  font-size: 20px;
  font-weight: 300;
}

.text-span-11 {
  font-style: italic;
  font-weight: 500;
}

.container-12 {
  padding-right: 10px;
  padding-left: 10px;
}

.collection {
  display: block;
  margin-right: auto;
  margin-left: auto;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: repeat(3, 1fr);
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.collection-wrap {
  display: block;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
}

.image-5 {
  padding: 0px;
  cursor: auto;
  max-width: 100%;
  width: 100%;
}

.div-block-2 {
  min-height: 162px;
  position: relative;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
  counter-increment: item-counter;
}

.div-block-3 {
  padding: 10px;
}

.div-block-4 {
  padding: 10px;
}

.div-block-5 {
  padding: 10px;
}

.columns-5 {
  display: block;
  width: 90vw;
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
}

.column-12 {
  padding: 0px;
}

.column-13 {
  padding: 0px;
}

.column-14 {
  padding: 0px;
}

.text-wrapper {
  position: absolute;
  bottom: 12px;
  padding-top: 0px;
  max-width: 100%;
}

.div-block-6 {
  width: 90vw;
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
}

.div-block-7 {
  display: block;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  padding: 10px;
  text-align: center;
}

.container-13 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.image-6 {
  display: block;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.image-7 {
  display: block;
  overflow: visible;
  max-height: 78vh;
  min-width: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  -o-object-fit: fill;
  object-fit: fill;
}

.nav-link-4 {
  color: #fff;
}

.nav-link-4:hover {
  font-weight: 700;
}

.nav-link-4.w--current {
  color: #e7e7e7;
}

.nav-link-4.current {
  font-weight: 500;
}

.nav-link-4.current:hover {
  font-weight: 700;
}

.body-3 {
  background-color: #222;
}

.submit-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 83vh;
  margin-top: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.container-14 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.div-block-8 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.heading-7 {
  font-size: 2em;
}

.submit-container {
  overflow: hidden;
  max-width: 60vw;
  margin-top: 0px;
  margin-right: auto;
  margin-left: auto;
  text-align: left;
}

.text-block-14 {
  margin-top: 40px;
  margin-bottom: 20px;
  font-family: Montserrat, sans-serif;
  color: #e7e7e7;
  font-size: 1em;
  text-align: left;
  text-decoration: none;
}

.text-block-14.submssiom-page {
  text-align: left;
}

.ok-button {
  width: fit-content;
  margin-top: 20px;
  margin-left: 0px;
  border-radius: 25px;
  background-color: #e7e7e7;
  font-family: Montserrat, sans-serif;
  color: #222;
  font-size: 1em;
  text-align: center;
}

.body-4 {
  background-color: #222;
}

.bold-text {
  text-decoration: none;
}

.text-span-12 {
  color: hsla(0, 0%, 90.6%, 0.6);
}

.text-span-13 {
  color: hsla(0, 0%, 90.6%, 0.6);
}

.text-span-14 {
  color: hsla(0, 0%, 90.6%, 0.61);
  text-align: left;
}

.button-3 {
  margin-right: 20px;
  margin-left: 0px;
  border-radius: 25px;
  background-color: #e7e7e7;
  font-family: Montserrat, sans-serif;
  color: #222;
}

.image-8 {
  margin-right: auto;
  margin-left: auto;
}

.previous {
  margin-right: 20px;
  border-radius: 25px;
  background-color: #e7e7e7;
  font-family: Montserrat, sans-serif;
  color: #222;
  font-size: 1em;
}

.text-span-15 {
  font-style: italic;
  text-decoration: underline;
}

.text-span-16 {
  font-style: italic;
  font-weight: 500;
}

.text-span-17 {
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
}

.text-span-18 {
  color: hsla(0, 0%, 90.6%, 0.6);
}

.text-span-19 {
  color: hsla(0, 0%, 90.6%, 0.6);
}

.text-block-15 {
  border: 1px none #e7e7e7;
  font-family: Montserrat, sans-serif;
  color: hsla(0, 0%, 90.6%, 0.6);
  font-size: 1em;
  text-align: center;
}

.image-9 {
  opacity: 0.51;
  color: #7b8797;
  text-align: center;
}

.div-block-9 {
  margin-bottom: 20px;
  border: 1px dashed #e7e7e7;
  font-size: 1em;
  text-align: center;
}

.button-4 {
  margin-bottom: 10px;
  padding-right: 22px;
  padding-left: 22px;
  border: 1px solid #e7e7e7;
  border-radius: 25px;
  background-color: #222;
  font-family: Montserrat, sans-serif;
  color: #e7e7e7;
  font-size: 1em;
}

.button-4:hover {
  background-color: #e7e7e7;
  color: #222;
}

.selection {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.text-block-16 {
  margin-right: 20px;
  font-family: Montserrat, sans-serif;
  color: hsla(0, 0%, 90.6%, 0.6);
  font-size: 12px;
  text-align: center;
}

.link-2 {
  padding-right: 20px;
  padding-left: 20px;
  color: hsla(0, 0%, 90.6%, 0.6);
  font-size: 12px;
}

.div-block-10 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: hsla(0, 0%, 90.6%, 0.6);
}

.footer-subscribe {
  position: relative;
  padding: 50px 30px 40px;
  border-bottom: 1px solid #e4ebf3;
}

.container-15 {
  width: 100%;
  max-width: 940px;
  margin-right: auto;
  margin-left: auto;
  color: #e7e7e7;
}

.footer-form-two {
  margin-bottom: 40px;
  padding: 64px;
  background-color: #f5f7fa;
}

.footer-form-container-two {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-form-title {
  font-size: 24px;
  line-height: 32px;
}

.footer-form-block-two {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-form-input {
  width: 460px;
  height: 48px;
  margin-bottom: 0px;
  padding: 12px 180px 12px 20px;
  border: 1px none #000;
}

.footer-form-input::-webkit-input-placeholder {
  color: rgba(26, 27, 31, 0.8);
}

.footer-form-input:-ms-input-placeholder {
  color: rgba(26, 27, 31, 0.8);
}

.footer-form-input::-ms-input-placeholder {
  color: rgba(26, 27, 31, 0.8);
}

.footer-form-input::placeholder {
  color: rgba(26, 27, 31, 0.8);
}

.button-primary {
  padding: 12px 25px;
  background-color: #1a1b1f;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.button-primary:hover {
  background-color: #32343a;
  color: #fff;
}

.button-primary:active {
  background-color: #43464d;
}

.button-primary.footer-form-button {
  position: absolute;
  right: 8px;
  padding: 8px 18px;
}

.footer-wrapper-three {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-block-three {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -20px;
  margin-left: -20px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-link-three {
  margin-right: 20px;
  margin-left: 20px;
  color: #1a1b1f;
  text-decoration: none;
}

.footer-link-three:hover {
  color: rgba(26, 27, 31, 0.75);
}

.footer-social-block-three {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: -32px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-social-link-three {
  margin-left: 32px;
}

.footer-divider-two {
  width: 100%;
  height: 1px;
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: #e4ebf3;
}

.footer-bottom-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-copyright {
  color: #3a4554;
}

.footer-legal-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-legal-link {
  margin-left: 24px;
  color: #3a4554;
}

.footer-legal-link:hover {
  color: #3a4554;
}

.columns-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.dropdown-div {
  position: relative;
}

.dropdown-content {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 150px;
  margin-top: 20px;
  margin-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  color: hsla(0, 0%, 90.6%, 0.67);
}

.profile-link {
  color: #e7e7e7;
}

.logout {
  color: #e7e7e7;
}

.columns-7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.bold-text-2 {
  font-size: 1em;
}

.link-3 {
  color: #e7e7e7;
}

.div-block-11 {
  border-bottom: 1px solid #e7e7e7;
}

.div-block-12 {
  padding-bottom: 40px;
  border-bottom: 1px solid #e7e7e7;
}

.text-span-20 {
  font-size: 10px;
  font-weight: 300;
}

.text-span-22 {
  font-size: 1.2em;
  letter-spacing: 0px;
  text-transform: capitalize;
}

.button-5 {
  width: 150px;
  margin-top: 20px;
  border: 1px solid #e7e7e7;
  border-radius: 30px;
  background-color: #e7e7e7;
  color: #222;
  text-align: center;
  cursor: pointer;
}

.button-5:hover {
  background-color: #e7e7e7;
  box-shadow: 1px 1px 3px 0 #e7e7e7;
  color: #222;
}

.button-5.sell {
  width: 150px;
  margin-left: 40px;
  background-color: #222;
  color: #e7e7e7;
}

.image-11 {
  max-width: 50%;
}

.link-block {
  margin-left: 20px;
  opacity: 0.88;
}

.text-span-23 {
  font-size: 22px;
}

.body-5 {
  background-color: #222;
}

.container-16 {
  padding-bottom: 0px;
}

.back-home {
  border-radius: 30px;
  background-color: #e7e7e7;
  font-family: Montserrat, sans-serif;
  color: #222;
  margin-top: 10px;
}

.submit-another-one {
  margin-left: 40px;
  border-radius: 30px;
  background-color: #e7e7e7;
  font-family: Montserrat, sans-serif;
  color: #222;margin-top: 10px;
}

.image-12 {
  position: relative;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  padding: 10px 10px 9px;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  text-align: center;
}

.heading-8 {
  font-family: Montserrat, sans-serif;
  color: #e7e7e7;
  font-size: 24px;
  line-height: 30px;
}

.text-block-17 {
  color: #e7e7e7;
  text-align: center;
}

.text-block-18 {
  font-family: Montserrat, sans-serif;
  color: #dfdfdf;
  font-size: 1.2em;
  font-weight: 500;
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 6em;
  }

  .hero {
    padding-top: 160px;
    padding-bottom: 160px;
  }

  .submit-wrapper {
    right: -6%;
  }

  .bg-blur._1 {
    left: 12.5%;
    top: -12.5vw;
    width: 75vw;
  }

  .logo-embed {
    width: 140px;
  }

  .main {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .bp-hero__content-grid {
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
    justify-items: center;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    text-align: center;
  }

  .swiper-slide {
    width: 45%;
    height: 50vh;
  }

  .slide-heading {
    font-size: 36px;
  }

  .heading-8 {
    font-size: 24px;
    line-height: 30px;
  }

  .slide-category {
    font-size: 12px;
  }

  .wheel-slider {
    position: relative;
  }

  ._wf-button__wrapper {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }

  .nav-item-text-mobile {
    display: block;
  }

  .nav-item-text-desktop {
    display: none;
  }

  .swiper-nav-btn.prev {
    left: 16%;
  }

  .swiper-nav-btn.next {
    right: 16%;
  }

  .brand {
    padding-bottom: 0px;
  }

  .icon {
    margin-top: 0px;
  }

  .nav-menu {
    padding: 10px;
    background-color: #222;
  }

  .text-block-2 {
    margin-top: 10px;
    font-size: 2em;
  }

  .menu-button {
    display: block;
    padding-top: 30px;
  }

  .section-2 {
    margin-top: 33px;
  }

  .columns {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .image {
    padding: 10px;
  }

  .text-block-3 {
    padding-top: 0px;
    padding-right: 10px;
    font-size: 14px;
  }

  .column {
    padding-top: 10px;
  }

  .container-5 {
    max-height: 80vw;
    max-width: 80vw;
  }

  .footer {
    padding-right: 0px;
    padding-left: 0px;
  }

  .wrap {
    width: 645px;
  }

  .wrap.w50-spcbetween {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .home-footer-socicons-wrap {
    margin-top: 20px;
  }

  .columns-5 {
    width: 100vw;
  }

  .submit-container {
    max-width: 90vw;
  }

  .container-15 {
    max-width: 728px;
  }

  .footer-form-two {
    padding: 40px;
  }

  .footer-form-title {
    margin-right: 15px;
  }

  .footer-form-input {
    width: 400px;
    padding-right: 180px;
  }

  .footer-wrapper-three {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .footer-social-block-three {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding-right: 20px;
    padding-left: 20px;
  }

  .container.is--nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .hero {
    padding-top: 40px;
    padding-bottom: 60px;
  }

  .c-nav-item-list {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .nav-item-link {
    margin-left: 32px;
  }

  .nav-link-icon {
    height: 20px;
  }

  .footer-nav-holder {
    padding-right: 12px;
    padding-left: 12px;
  }

  .in--footer {
    margin-bottom: 12px;
  }

  .submit-wrapper {
    right: -6%;
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }

  .wlfwc-holder {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }

  .nav {
    padding-top: 12px;
  }

  .nav-item-list {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .nav-item-list.is--main-nav {
    margin-top: 8px;
    margin-right: 0px;
    padding-left: 0px;
  }

  .nav-topic-wrap {
    margin-top: 0px;
    border-top-style: none;
  }

  .logo-embed {
    width: 120px;
  }

  .bp-hero__content-grid {
    max-width: 90%;
  }

  .swiper-slide {
    width: 60%;
  }

  ._wf-button__wrapper {
    margin-right: -20px;
    margin-bottom: -20px;
  }

  .swiper-nav-btn {
    width: 40px;
    height: 40px;
    padding: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .swiper-nav-btn.prev {
    left: 14%;
  }

  .swiper-nav-btn.next {
    right: 14%;
  }

  .swiper-nav-btn-icon {
    width: 40%;
  }

  .brand {
    padding-left: 15px;
  }

  .text-block-2 {
    font-size: 2em;
  }

  .container-2 {
    margin-top: 84px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .menu-button {
    padding-top: 30px;
    padding-right: 20px;
  }

  .columns {
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .image {
    display: inline-block;
    width: 80vw;
    height: 80vw;
  }

  .text-block-3 {
    padding-right: 0px;
  }

  .column {
    padding-right: 20px;
    padding-left: 20px;
  }

  .column-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .wrap {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }

  .home-footer-socicons-wrap {
    margin-top: 20px;
  }

  .heading-5 {
    font-size: 1.5em;
  }

  .columns-5 {
    width: 100vw;
  }

  .div-block-6 {
    width: auto;
  }

  .text-block-14 {
    font-size: 1em;
  }

  .footer-subscribe {
    padding-right: 15px;
    padding-left: 15px;
  }

  .footer-form-container-two {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .footer-form-title {
    margin-right: 0px;
  }

  .footer-form-block-two {
    width: 100%;
    margin-top: 20px;
  }

  .footer-form-input {
    width: 100%;
  }

  .footer-block-three {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .footer-link-three {
    margin-bottom: 10px;
  }

  .footer-social-block-three {
    margin-top: 10px;
  }

  .footer-bottom-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .footer-copyright {
    font-size: 14px;
    line-height: 20px;
  }

  .footer-legal-block {
    margin-top: 15px;
    margin-left: -24px;
  }

  .footer-legal-link {
    font-size: 14px;
    line-height: 20px;
  }
  
  .feature-image-wrapper {
    justify-content: center;
  }
  .section-2.sold-warpper {
    margin-top: 73px;
  }
}

@media screen and (max-width: 479px) {
  h2 {
    font-size: 24px;
  }

  p {
    font-size: 16px;
  }

  .container {
    padding-right: 16px;
    padding-left: 16px;
  }

  .animate-this-shit {
    font-size: 4.2em;
  }

  .subline {
    font-size: 10px;
  }

  .db {
    display: inline;
  }

  .db.mobile-db {
    display: block;
  }

  .form-block {
    min-width: auto;
  }

  .submit-wrapper {
    bottom: -29%;
  }

  .nav-item-list.is--main-nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .guide-header {
    margin-left: -27px;
  }

  .guide-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  .swiper-slide {
    height: 40vh;
  }

  .swiper-nav-btn.prev {
    left: 5%;
  }

  .swiper-nav-btn.next {
    right: 5%;
  }

  .control-btn:hover {
    background-color: rgba(126, 126, 126, 0.5);
  }

  .brand {
    padding-left: 10px;
  }

  .nav-link-3.buttom {
    margin-right: 10px;
  }

  .icon {
    margin-top: 5px;
  }

  .nav-menu {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    display: block;
    width: 80vw;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #222;
    text-align: left;
  }

  .text-block-2 {
    margin-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 1.6em;
  }

  .container-2 {
    position: fixed;
    margin-top: 79px;
    padding-left: 10px;
  }

  .menu-button {
    padding-top: 22px;
    padding-right: 10px;
  }

  .menu-button.w--open {
    background-color: #222;
  }

  .section-2 {
    display: block;
    max-height: 50vh;
  }

  .columns {
    display: none;
    width: auto;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .image {
    width: auto;
    height: auto;
  }

  .text-block-3 {
    font-size: 12px;
  }

  .column-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    align-self: flex-start;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .container-5 {
    max-height: 90vw;
    max-width: 90vw;
  }

  .feature-image-wrapper {
    max-height: 48vh;
    justify-content: center;
  }

  .print {
    font-size: 12px;
  }

  .container-7 {
    display: block;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .columns-2 {
    /* -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; */
  }

  .text-block-6 {
    font-size: 12px;
    color: #878e98;
  }

  .gallery.my-gallery {
    margin-right: auto;
    margin-left: auto;
  }

  .column-7 {
    padding: 10px;
  }

  .column-8 {
    padding: 10px;
  }

 .text-block-5 {
   text-align: left;
 }

  .heading-4 {
    font-size: 1em;
  }

  .about {
    margin-top: 0px;
    padding: 10px;
  }

  .about.page._1.top {
    padding-bottom: 10px;
  }

  .italic-text-2 {
    font-style: italic;
  }

  .heading-5 {
    padding-right: 10px;
    padding-left: 10px;
    font-size: 1.2em;
    text-align: center;
  }

  .heading-6 {
    margin-right: 0px;
    margin-left: 0px;
    font-size: 1.5em;
  }

  .columns-5 {
    width: 100vw;
    padding-right: 0px;
    padding-left: 0px;
  }

  .div-block-6 {
    width: 100vw;
    padding-right: 10px;
    padding-left: 10px;
  }

  .submit-1 {
    height: 70vh;
    margin-top: 0px;
  }

  .submit-container {
    text-align: center;
  }

  .text-block-14 {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }

  .ok-button {
    font-size: 1em;
  }

  .text-block-16 {
    margin-top: 20px;
    margin-right: 0px;
  }

  .container-15 {
    max-width: none;
  }

  .footer-form-two {
    padding: 20px;
  }

  .footer-form-block-two {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .footer-form-input {
    margin-bottom: 10px;
    padding-right: 20px;
  }

  .button-primary.footer-form-button {
    position: relative;
    right: 0px;
  }

  .footer-bottom-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .image-10 {
    margin-right: auto;
    margin-left: auto;
  }

  .submit-another-one {
    margin-left: 0px;
  }

  .w-col {
    padding-top: 10px;
  }

  .image-7 {
    max-height: 100%;
  }

  .section-2.sold-warpper {
    position: relative;
    margin-top: 69px;
  }

  .image-12 {
    position: absolute;
    z-index: 2;
  }
}

#w-node-_47be8223-f609-eb05-0a74-b10a0fa572de-523f21cc {
  -ms-grid-row: span 3;
  grid-row-start: span 3;
  -ms-grid-row-span: 3;
  grid-row-end: span 3;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row-align: start;
  align-self: start;
}

@media screen and (max-width: 991px) {
  #w-node-_47be8223-f609-eb05-0a74-b10a0fa572de-523f21cc {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row-align: start;
    align-self: start;
  }
}